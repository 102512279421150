import resolveLocaleRoute from "@/Helpers/resolveLocaleRoute";
import FrontLayout from "@/Layouts/System/FrontLayout";
import { Link } from "@inertiajs/react";
import { useTranslation } from "react-i18next";

const Error = ({ status }) => {
    const { t, i18n } = useTranslation();

    const description = {
        503: t('error_page.503'),
        500: t('error_page.500'),
        404: t('error_page.404'),
        403: t('error_page.403'),
    }[status]

    return (
        <FrontLayout>
            <div className="pt-20 pb-10 text-center">
                <div className="inline-flex mx-auto relative text-center">
                    {status === 500 &&
                        <img src="/storage/icons/clocks-icon.svg" alt="Ikona trybów" className="absolute top-8 -right-4 z-10"/>
                    }
                    {status === 404 &&
                        <img src="/storage/icons/sad-icon.svg" alt="Ikona trybów" className="absolute top-8 -right-4 z-10"/>
                    }
                    <h1 className="text-center text-[242px] text-dark-blue font-bold relative z-0 mb-10">{status}</h1>
                </div>
                <p className="text-24 text-black">{description}</p>
                <Link className="w-[177px] h-10 px-[18px] py-2.5 bg-dark-blue rounded-lg justify-center items-center gap-2.5 inline-flex text-white mt-10 border-dark-blue border-[2px] font-semibold ease-in-out transition-all duration-150 hover:bg-transparent hover:bg-yellow hover:text-black" title="Powrót do strony głównej" href={resolveLocaleRoute('front.home', null, i18n.resolvedLanguage)}>
                    {t('error_page.homepage')}
                </Link>
            </div>
        </FrontLayout>
    )
}

export default Error;